$sm:480px;
$md:768px;
$lg:1024px;
$xl:1200px;
$xxl:5500px;

@mixin sm{
    @media screen and (max-width:($sm)){
        @content;
    }
}
@mixin md{
    @media screen and (max-width:($md)){
        @content;
    }
}
@mixin lg{
    @media screen and (max-width:($lg)){
        @content;
    }
}
@mixin xl{
    @media screen and (max-width:($xl)){
        @content;
    }
}
@mixin xxl{
    @media screen and (max-width:($xxl)){
        @content;
    }
}
