@media print {
    @page {
        size: A4;
        margin: 20mm 16mm 16mm 16mm;
    }

    thead>tr> {
        border-bottom: 2px solid rgb(75, 184, 227);

    }

    thead>tr>th {
        padding-left: 10px;
        text-align: left;
        width: 100px;
        border-bottom: 2px solid skyblue;
    }

    tbody>tr>td {
        padding-left: 10px;
        text-align: left;
        border-bottom: 2px solid skyblue;
        padding-bottom: 2px;
    }

    .print_trimester_paid {
        /* background-color: rgb(127, 172, 223); */
        background-color: rgb(255, 255, 255);
    }

    .print_trimester_unpaid {
        /* background-color: rgb(127, 172, 223); */
        background-color: rgb(255, 255, 255);
    }

    .print_row_color {
        background-color: rgba(236, 245, 255, 0.858);
    }

    .print_pontualite_detail {}

    .logoPrinted {
        width: 100px;
        height: 80px;
        object-fit: contain;

        /* style={{ width: '100px', height: '80px', objectFit: 'contain', display: 'none' }} */
    }


}

.listHeader {
    /* visibility: hidden; */
    display: none;

    @media print {
        /* visibility: visible; */
        display: block;
        column-span: all;
    }
}

.headerLibelle {
    font-size: 13px;
    color: #3c72d5ed;

    @media print {

        .p,
        .a,
        .r,
        .j {
            text-align: center;
        }
    }
}

.presenceElevePrint,
.presencePersonnelPrint {
    display: none;

    @media print {
        display: block;
    }
}