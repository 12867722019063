@media print {
    @page {
        size: A4;
        margin: 20mm 16mm 16mm 16mm;
    }

}

.num {
    font-size: 12px;
    width: 10px;
    text-align: center;

    @media print {
        width: 10px;
    }
}

.date {
    font-size: 12px;
    width: fit-content;
    text-align: center;

    @media print {
        width: 150px;
        text-align: center;
    }
}

.heure {
    font-size: 12px;
    width: 40px;
    text-align: center;

    @media print {
        width: 30px;
    }
}

.type {
    font-size: 12px;
    width: fit-content;
    text-align: right;

    @media print {
        width: 150px;
    }
}

.montant {
    font-size: 12px;
    width: 20px;
    text-align: center;

    @media print {
        width: 50px;
    }
}

.auteur {
    font-size: 12px;
    width: fit-content;
    text-align: center;

    @media print {
        width: fit-content;
        text-align: left
    }
}