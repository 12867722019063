$bg-color: #2b2929d0;
$text-color: #2b2929d0;

.dark {
  background-color: rgba(30, 30, 30, 0.769);
  color: #e7e5e5;

  .d_body {
    background-color: rgba(30, 30, 30, 0.518) !important;

    .d_navBar {
      background-color: chartreuse;
    }

    //leftSide
    .leftside {
      background-color: $bg-color;
      color: $text-color;
      border-right: 2px solid #888;
    }

    //sidebar
    .d_sidebar {
      background-color: #2b2929d0;
      color: #999;
      //   background-image: linear-gradient(to top, #234342 0%, #b3e7f7 100%);

      .dashboarContainer {
        background-color: #2b2929d0;

        .dashbordItem {
          background-color: $bg-color;
          color: $text-color;
        }
      }

      .graphicContainer {
        background-color: $bg-color;

        .d_depenseChart {
          background-color: $bg-color;
          color: $text-color;
        }
      }
    }
  }
}
