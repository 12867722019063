* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Nunito', sans-serif; */
}

.darkMode {
  background-color: rgba(30, 30, 30, 0.769);
  color: #e7e5e5;
}
