.topPersonnel {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: left;
  font-family: PoppinsRegular;
  // margin: 0px 50px;

  .card_Year {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 48%;

    .card-title {
      margin: 0;
      font-size: 14px;
      color: #333;
    }

    .item-list {
      list-style-type: none;
      padding: 0;
      margin: 15px 0;
      max-height: 200px;
      overflow-y: auto;
      background-color: #e5eaed;
      font-size: 14px;
    }

    .item-list li {
      padding: 8px;
      border-bottom: 1px solid #ffffff;
      display: flex;
      justify-content: space-between;

      .actionSection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 11px;

        .edit {
          color: #28a745;
          cursor: pointer;
        }
        .archive {
          color: #db1d1d;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }
  }
}
