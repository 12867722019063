@import "../../style/responsive.scss";
@import "../../style/variable.scss";

.SingleCommunication {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;

  .dialog {
    background-color: aliceblue;
    width: 60vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 5px;

    @include md {
      width: 70vw;
    }
    @include sm {
      width: 90vw;
    }

    .headerUpdate {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titleUpdate {
        padding: 5px 8px;
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left_ {
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: PoppinsRegular;

          span {
            font-size: 13px;
            color: $secondary-green-color;
          }

          .ico {
            color: $secondary-green-color;
          }
        }
        .right_ {
          display: flex;
          align-items: center;
          gap: 10px;

          .ico {
            color: $secondary-green-color;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 10px;
      font-family: PoppinsRegular;
      font-size: 14px;
      padding: 10px 40px;

      .items_com {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        select {
          width: 100%;
          height: 35px;
          padding: 10px;
          background-color: aliceblue;
          outline: none;
          border: none;

          option {
            color: #333;
          }
        }
        textarea {
          width: 100%;
          padding: 5px 10px;
          background-color: none;
          outline: none;
          border: none;
        }
        input {
          width: 100%;
          height: 35px;
          padding: 10px;
          background-color: none;
          outline: none;
          border: none;
        }
      }
      .items_com_text {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: flex-start;

        textarea {
          width: 100%;
          padding: 0px 10px;
          background-color: none;
          outline: none;
          border: none;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      gap: 10px;
      button {
        border: none;
        padding: 5px 10px;
        width: 80px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .circular {
          color: $primary-white-color;
        }

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;

          &:hover {
            // background-color: transparent;
            color: #d4d4d4;
          }
        }
      }
    }
  }
}
