@import "../../../style/responsive.scss";
@import "../../../style/variable.scss";

.box_one {
  display: flex;
  flex-direction: column;
  width: 100%;

  .card_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;

    @include lg {
      gap: 12px;
      font-size: 13px;
    }
    @include md {
      font-size: 12px;
    }

    span {
      color: $secondary-black-color;
      font-size: 12px;
    }

    h1 {
      font-size: 22px;

      @include lg {
        font-size: 17px;
      }
    }
  }

  .card_icon {
    //   padding: 8px 10px;
    //   background-color: #6940ac41;
    //   border-radius: 15px;

    .ico {
      color: rgba(124, 76, 200, 0.699);
    }
  }
}
