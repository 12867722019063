.topPonctualite {
  display: flex;
  gap: 20px;
  font-family: PoppinsRegular;

  .card_Year {
    flex: 2;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;

    .card-title {
      margin: 0;
      font-size: 18px;
      color: #333;
      font-size: 14px;
      font-size: 14px;
    }

    .item-list {
      list-style-type: none;
      padding: 0;
      margin: 15px 0;
      max-height: 200px;
      overflow-y: auto;
      background-color: #e5eaed;
    }

    .item-list li {
      padding: 8px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
      }

      .actionSection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .edit {
          color: #28a745;
          cursor: pointer;
          font-size: 11px;
        }
        .archive {
          color: #db1d1d;
          cursor: pointer;
          text-decoration: underline;
          font-size: 11px;
        }
      }
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      gap: 5px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }
    .table_component {
      overflow: auto;
      width: 100%;
      max-height: 250px;
      margin-top: 10px;

      table {
        width: 100%;
        margin: 15px auto;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        color: #333;
        background-color: lightgray;
      }

      thead th {
        position: sticky; /* Makes header sticky */
        top: 0; /* Sticks to the top of the table */
        background-color: #afd9f5; /* Background color for the header */
        z-index: 1; /* Ensures the header is above the body */
      }

      th,
      td {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 14px;

        &.actionSection {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 11px;

          &:hover {
            display: flex;
          }

          .edit {
            color: #fbfeff;
            padding: 2px 5px;
            background-color: #28a745;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              color: #fbfeff;
              background-color: #28a746b7;
            }
          }
          .archive {
            color: #8b1f1f;
            padding: 2px 5px;
            background-color: #a73b284e;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
              color: #e8e1e1;
              background-color: #8b1f1f;
            }
          }
        }
        &.actionHeader {
          text-align: center;
        }
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }
      /* Alternate row colors */
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:nth-child(odd) {
        background-color: #e5eaed;
      }
    }
  }
  .card_Classe {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 3;

    .card-title {
      margin: 0;
      font-size: 14px;
      color: #333;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      gap: 5px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }

    .table_component {
      overflow: auto;
      width: 100%;
      max-height: 250px;
      margin-top: 10px;

      table {
        width: 100%;
        margin: 15px auto;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        color: #333;
        background-color: lightgray;
      }

      thead th {
        position: sticky; /* Makes header sticky */
        top: 0; /* Sticks to the top of the table */
        background-color: #afd9f5; /* Background color for the header */
        z-index: 1; /* Ensures the header is above the body */
      }

      th,
      td {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 14px;

        &.actionSection {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 11px;

          &:hover {
            display: flex;
          }

          .edit {
            color: #fbfeff;
            padding: 2px 5px;
            background-color: #28a745;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              color: #fbfeff;
              background-color: #28a746b7;
            }
          }
          .archive {
            color: #8b1f1f;
            padding: 2px 5px;
            background-color: #a73b284e;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
              color: #e8e1e1;
              background-color: #8b1f1f;
            }
          }
        }
        &.actionHeader {
          text-align: center;
        }
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }
      /* Alternate row colors */
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:nth-child(odd) {
        background-color: #e5eaed;
      }
    }
  }
}
