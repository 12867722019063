@import "../../../style/responsive.scss";
@import "../../../style/variable.scss";

.UpdateClass {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;

  .dialog_update {
    background-color: aliceblue;
    width: 50vw;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    height: max-content;
    padding-bottom: 20px;

    @include md {
      width: 70vw;
    }
    @include sm {
      width: 90vw;
    }

    .headerUpdate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #333;

      .titleUpdate {
        padding: 0px 10px;
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left_ {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            color: $secondary-green-color;
            font-size: 15px;
          }

          .ico {
            color: $secondary-green-color;
          }
        }

        .ico {
          color: $secondary-green-color;
          cursor: pointer;
        }
      }
    }

    .body_update {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0px 40px;

      .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
        margin-top: 10px;

        label {
          width: 100%;
        }

        @include md {
          width: 48%;
        }

        @include sm {
          width: 45%;
        }

        label {
          color: #353535;
          font-size: 12px;
          font-family: poppinsRegular;
        }

        input {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          background-color: transparent;
          border-radius: 1px;
          padding: 0px 20px;

          &:focus {
            outline: none;
          }
        }

        select {
          height: 30px;
          width: 100%;
          border: 1px solid #999;
          border-radius: 2px;
          background-color: transparent;
          padding: 5px 20px;
          color: $secondary-black-color;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;

      button {
        border: none;
        padding: 10px;
        width: max-content;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;

        .circular {
          color: $primary-white-color;
        }

        &:first-child {
          border: 1px solid $secondary-black-color;
          color: $secondary-black-color;
          background-color: transparent;

          &:hover {
            border: 1px solid $primary-red-color;
            color: $primary-red-color;
          }
        }

        &:last-child {
          background-color: $secondary-green-color;
          border: 1px solid $secondary-green-color;
          color: $primary-white-color;
          transition: background-color 0.5s;

          &:hover {
            background-color: transparent;
            color: $secondary-green-color;
          }
        }
      }
    }
  }
}
