.MyloaderLogin {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;

  .loader {
    // background-color: rgba(240, 248, 255, 0.954);
    box-shadow: rgba(213, 213, 213, 0.064) 0px 5px 15px;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
