.profilImage {
    width: 50px;
    height: 50px;
}

.topProfile {

    display: flex;
    gap: 20px;
    margin: 0px 50px;


    .left {
        flex: 3;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;
        background-color: #fff;
        border-radius: 8px;

        .editButton {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            font-size: 12px;
            color: #76b6f7;
            font-style: italic;
            background-color: #7551f818;
            cursor: pointer;
            border-radius: 0px 0px 0px 5px;
        }

        .titleProfile {
            font-size: 18px;
            margin-bottom: 15px;
            color: #555;
            // margin-top: 10px;
        }

        hr {
            margin-bottom: 10px;
        }

        .item {
            display: flex;
            gap: 20px;

            .itemImg {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
            }

            .details {
                .itemTitle {
                    margin-bottom: 10px;
                    color: #555;
                }

                .detailItem {
                    margin-bottom: 10px;
                    font-size: 14px;

                    .itemKey {
                        font-weight: bold;
                        color: gray;
                        margin-right: 5px;
                    }

                    .itemValue {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .right {
        flex: 5;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        min-height: 350px;
        background-color: #fff;
        border-radius: 8px;

        .rightHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .titleAdmin {
                font-size: 18px;
                font-weight: bold;
                color: #999;

            }

            .headerLink {
                color: #A3C8ED;
                text-decoration: underline;
                font-size: 13px;
                cursor: pointer;
                font-style: italic;
            }
        }

        .itemContainer {

            table {
                border-collapse: collapse;

                tr {
                    text-align: left;
                    font-size: 13px;

                    border-bottom: 1px solid #55555593;

                    th {
                        color: #555;
                    }
                }

                tbody {
                    tr {
                        height: 35px;
                    }

                    .userName {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        margin-top: 5px;
                    }
                }
            }

            display: flex;
            flex-direction: column;
            margin-top: 25px;

            .itemAdmin {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;

                span {
                    font-size: 14px;
                    font-weight: 300;
                    margin-right: 20px;
                    color: #999;
                }

                .itemAdminMail {
                    color: #A3C8ED;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .itemFunction {
                    color: #888;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }


}