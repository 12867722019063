@import "../../style/responsive.scss";
@import "../../style/variable.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  position: fixed;
  top: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 10;

  .logo {
    display: flex;
    align-items: center;

    @include md {
      margin-left: -20px;
    }

    img {
      width: 100px;
      height: 40px;
      object-fit: contain;
    }

    .appname {
      font-size: 26px;
      font-weight: 700;
      margin-left: -20px;
      // margin-top: 5px;

      @include sm {
        display: none;
      }

      span {
        &:first-child {
          color: $primary-blue-color;
        }

        &:last-child {
          color: #253341;
        }
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #04710b53;
    padding: 0 20px;
    padding-top: 5px;
    border-radius: 20px;

    .message,
    .notification {
      position: relative;
      cursor: pointer;

      @include sm {
        display: none;
      }

      .icon {
        color: $primary-green-color;
      }

      span {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        color: $primary-white-color;
        right: -8px;
        top: -2px;
        font-size: 10px;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .language {
      display: flex;
      align-items: center;
      gap: 5px;

      @include md {
        display: none;
      }

      .langIcon {
        padding: 2px;
        width: 26px;
        height: 20px;
        border-radius: 3px;
        background-color: rgb(184, 184, 184);
        color: $secondary-black-color;
      }

      span {
        color: $secondary-black-color;
        font-size: 12px;
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      @include md {
        margin-top: -5px;
      }

      .icon {
        padding: 2px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        color: $primary-white-color;
        background-color: rgb(128, 128, 128);
      }

      span {
        font-size: 12px;
        font-weight: 600;
        color: $secondary-black-color;
        @include md {
          display: none;
        }
      }

      &:last-child {
        color: $secondary-black-color;
        @include md {
          display: none;
        }
      }
    }
  }

  .setting_menu {
    .menu_items {
      font-size: 12px;
    }
  }
}
