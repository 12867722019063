.editHospital {
    width: 100%;
    /* margin: 20px; */
    padding: 2rem;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.16) 0px 1px 2px 3px; */
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editeTitleNameIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* h2,
.titleName {
    margin-right: 15px;
    font-family: montserrat, serif;
} */

.editIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    margin-top: -5px;
    margin-right: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 0px 2px 2px, rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;

}

.editBackIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    margin-top: -5px;
    margin-right: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 2px 0px 2px 2px, rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;

}

.arrowBackIcon {
    text-decoration: none !important;
}

.deleteIcon {
    text-decoration: none !important;
    color: rgba(235, 20, 20, 0.897);
}

.createBtn {
    border: none;
    padding: 5px 10px;
    background: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
}

.PaiementContainer {
    display: flex;
    /* justify-content: center; */
    margin-top: 20px;
}

.studentCard {
    width: 30%;
    padding: 10px;
    height: auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 0px 5px 5px, rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;
}

.hospitalEditDetail {
    width: 70%;
    padding: 20px 20px 0px 20px;
    margin-left: 20px;
    border-radius: 5px;
    gap: 2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 0px 5px 5px, rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;
}

.titlePaimentIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iconOption {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: rgb(119, 118, 118) !important;
    cursor: pointer;

}

.studentImg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: '50%';
}

.studentPP {
    border-radius: 50%;
    border: 7px solid #9db2d857;
    object-fit: cover;
    width: 250px;
    height: 250px;


    /* width: 80%;
    height: 80%; */
}

.hospitalTitle>h6 {
    font-family: montserrat, serif;
    font-weight: 700;
}

.hospitalNumm {
    font-family: montserrat, serif;
    font-weight: 300;

}

.studentDetail {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}

.studentItem {
    display: flex;
    align-items: center;
    font-family: poppinsRegular;
}

.keyItem {
    font-size: 14px;
    color: #888;
}

.valueItem {
    font-size: 14px;
}

.studentItem {
    display: flex;
    gap: 5px;

}


.iconDetail {
    font-size: 16px !important;

}

.hospitalEditDetail {
    display: flex;
    flex-direction: column;
}

.upadateTitle {
    font-size: 18px;
    font-weight: 600;
    color: #181717;
    font-family: poppinsRegular;
}

.updateForm {
    border-bottom: 1px solid rgb(170, 170, 170);
}

.updateLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemRowUpdate {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.itemRowUpdate>label {
    font-size: 14px;
    font-family: montserrat, serif;

}

.itemRowUpdate>input {
    border: none;
    border-bottom: 2px solid rgb(122, 121, 121);
    width: 250px;
    height: 30px;
    background-color: rgba(78, 161, 161, 0.651);
    border-radius: 8px;
    font-size: 14px;
    padding-left: 10px;
}

.formChart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */

}

.updateRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding:40px 10px;
    font-family: poppinsRegular;
}

.updateBtn {
    border: none;
    padding: 2px 25px;
    background: rgb(26, 26, 141);
    border-radius: 5px;
    color: white;
    font-weight: 400;
    cursor: pointer;
}

.paiementDetLeft{
    display: flex;
    align-items: center;
    gap: 10px;
}

.ChartDetail {
    padding-right: 5px;
    border-right: 2px solid #2222;
}

.chart {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
    /* align-items: center; */

}

.chartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infoFin {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;

}

/* ELEVES STYLES */
.eleveContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;

}

.listEleve {
    flex: 5;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 5px 0px 5px 5px, rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;
}

.listTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #1565C0;
    padding: 7px 7px; */
}

.listTitleRight {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
}

.rightBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.spanBtn {
    padding: 2px 4px;
    color: #1e1c1c;
    font-weight: 500;
    background-color: #d6d6d688;
    border-radius: 2px;
    font-family: poppinsRegular;
    font-size: 12px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spanLabel{
    font-family: poppinsRegular;
    font-size: 12px; 
    color: #1565C0;
}

.spanBtnClicked {
    padding: 2px 4px;
    color: #1565C0;
    background-color: #9db2d857;
    border-radius: 2px;
    font-family: poppinsRegular;
    font-size: 12px;
    width: 120px;
    display: flex;justify-content: center;align-items: center;
    transition: all .6s;
}
.icoArr{
    font-size: 13px;
}

.clickedSpan {
    padding: 2px 4px;
    color: #1153a0;
    font-weight: bold;
    background-color: #9db2d857;
    border-radius: 2px;
    font-family: poppinsRegular;
    font-size: 12px;
    width: 90px;
    display: flex;justify-content: center;align-items: center;
    transition: all .6s;
}

.clickedNotSpan {
    padding: 2px 4px;
    color: #514d4dd9;
    font-weight: 500;
    background-color: #d6d6d688;
    border-radius: 2px;
    font-family: poppinsRegular;
    font-size: 12px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul .presenceEleve {
    border: 2px solid #000;
}