$text-color: #888;

.pnf {

    // margin-left: 0%;
    // background-color: #888;
    margin: 10% auto;

    .pagenotfound {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: #888;
        }

        h4 {
            color: #5069c5;
        }

        img {
            width: 150px;
        }
    }
}