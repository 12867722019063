@import "../../style/responsive.scss";
@import "../../style/variable.scss";

$primary-color: #0090ead5;
$secondary-color: #0545c0;
$transparent-color: #9db2d857;
$white-color: #ffffff;
$dark-color: #100f0f;
$text-color: #888;
$outline-color: #1e1e1e13;
$pink-color: rgba(205, 93, 73, 0.767);

.add_button_pers {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 15px;
  cursor: pointer;
  .ico {
    font-size: 16px;
    color: #28a745;
  }
}

.Searchbar_ {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
  width: 350px;
  height: 30px;

  .icon {
    color: #4f7396;
    font-size: 18px;
  }

  input {
    width: 100%;
    border: none;
    padding-left: 5px;
    font-size: 14px;
    color: #4f7396;
    background-color: transparent;

    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 12px;
      color: #4f7396;
    }
  }
}

.containerMsg {
  display: flex;
  padding: 5px;
  gap: 20px;

  .msgList {
    flex: 6;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .com_title {
      display: flex;
      justify-content: space-between;

      .com_option {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: PoppinsRegular;

        .com_del {
          display: flex;
          align-items: center;
          color: #5a0202;
          cursor: pointer;
          gap: 10px;

          span {
            font-size: 13px;
            text-decoration: underline;
          }

          .icoDel {
            cursor: pointer;
          }
        }
        .filter {
          display: flex;
          align-items: center;
        }
      }
    }

    .com_content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .box_msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $primary-white-color;
        border: 1px solid #dbdce2;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        gap: 20px;

        &:hover {
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          cursor: pointer;
        }

        .case {
          width: 35px;
          height: 15px;
          border: 1.4px solid #202020;
          border-radius: 2px;
          cursor: pointer;
        }
        .filledCase {
          width: 35px;
          height: 15px;
          border: 1.4px solid #202020;
          border-radius: 2px;
          cursor: pointer;
          background-color: #151515;
        }

        .left {
          display: flex;
          align-items: center;
          gap: 5px;

          input {
            width: 18px;
            height: 18px;
            border: 1px solid #dbdce2;
            cursor: pointer;
          }

          .message {
            display: flex;
            align-items: center;
            gap: 10px;

            .sender {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 12px;
              font-weight: 600;
              color: $secondary-black-color;
              overflow: hidden;

              @include lg {
                display: none;
              }
              @include md {
                display: none;
              }

              .avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 40px;
                min-height: 40px;
                border-radius: 20px;
                background-color: #9db2d879;
                margin-right: 10px;

                span {
                  font-size: 20px;
                  font-weight: bolder;
                  font-family: PoppinsRegular;
                  color: rgba(106, 110, 109, 0.801);
                }
              }

              &.small {
                font-size: 12px;
                font-weight: 600;
                color: $secondary-black-color;

                @include xxl {
                  display: none;
                }

                @include lg {
                  display: flex;
                  align-items: center;
                }
                @include md {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .text {
              font-size: 12px;
              width: fit-content;
              margin-left: 10px;
              font-family: RobotoRegular;
              .label_recipient {
                font-weight: bold;
                font-family: PoppinsRegular;
              }
              .label_subject {
                font-weight: bold;
                font-family: PoppinsRegular;
                text-decoration: underline;
                padding-inline: 10px;
              }

              .date {
                color: #3a5871de;
                font-style: italic;
                margin-left: 10px;
                text-decoration: underline;
              }
              .label_send {
                color: #00ba1c;
                font-style: italic;
                margin-left: 10px;
                text-decoration: underline;
              }
              .label_draft {
                color: #ba5400;
                font-style: italic;
                margin-left: 10px;
                text-decoration: underline;
              }

              @include md {
                display: none;
              }
            }
          }
        }

        .icons {
          display: flex;
          align-items: center;
          gap: 5px;

          .number {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $primary-red-color;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            icon {
              position: absolute;
              color: $primary-white-color;
              font-size: 12px;
            }
          }

          .icon {
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialogMsg {
    flex: 2;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: PoppinsRegular;
    padding-bottom: 40px;

    // height: fit-content;
    height: 500px;

    .items_msg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left_part {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
          // font-weight: bold;
          font-size: 12px;
        }

        .icoContainer {
          padding: 5px;
          background-color: #3333331e;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          .ico {
            font-size: 18px;
            color: #333;
          }
        }
      }
      span {
        font-size: 14px;
      }
    }

    button {
      width: 100%;
      background-color: $primary-color;
      color: white;
      border: 1px solid $primary-color;
      padding: 7px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: bold;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      cursor: pointer;
      font-family: PoppinsRegular;
      margin-bottom: 10px;
    }
  }
}
