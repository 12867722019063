$primary-color: #0091ea;
$secondary-color: #0545c0;
$link-color: #3c72d5ed;
$transparent-color: #9db2d857;
$white-color: #ffffff;
$dark-color: #100f0f;
$text-color: #888;
$outline-color: #1e1e1e13;
$green-color: #1dd40813;

.leftside {
  position: fixed;
  width: 200px;
  min-width: 200px;
  height: 90vh;
  min-height: 90vh;
  padding: 10px 10px 20px 0px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid $outline-color;
  background-color: $white-color;
  z-index: 6;
  font-family: PoppinsRegular;
  margin-top: 50px;

  .primaryMenu {
    padding: 10px 0 0 0px;

    .title {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 600;
      // color: $text-color;
      border: inherit;
    }

    .menu {
      margin-top: 15px;
      font-size: 12px;
      color: #333;
      margin-bottom: 20px;

      .menuList {
        display: flex;
        gap: 8px;
        align-items: center;
        list-style: none;
        padding: 6px 0px;
        margin-left: 5px;

        &:hover {
          background-color: $transparent-color;
        }

        .menuLabel {
          font-size: 12px;
          color: #141414;
          // color: $link-color;
        }

        .menuIcon {
          font-size: 10px !important;
          color: #141414;
          padding-left: 15px;
        }
      }
      .menuList-active {
        display: flex;
        gap: 8px;
        align-items: center;
        list-style: none;
        padding: 6px 0px;
        background-color: #9db2d879;
        margin-left: 5px;
        border-radius: 5px;

        .menuLabel {
          font-size: 12px;
          color: #141414;
          // color: $link-color;
        }

        .menuIcon {
          font-size: 10px !important;
          color: #141414;
          padding-left: 15px;
        }
      }
    }
  }

  .themeModeCard {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    .light {
      width: 25px;
      height: 25px;
      border: 1px solid $dark-color;
      background-color: $outline-color;
      border: 2px solid $primary-color;
      border-radius: 4px;
      cursor: pointer;
    }

    .dark {
      width: 25px;
      height: 25px;
      background-color: $text-color;
      border: 2px solid $primary-color;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
