$text-color: #888;

.pnf {
    margin-top: 10%;
    margin-left: 40%;

    .pagenotfound {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: #888;
        }

        img {
            width: 150px;
        }
    }
}