@import "../style/responsive.scss";
@import "../style/variable.scss";

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-top: 55px;
  margin-left: 190px;
  width: 100%;

  .header_dash {
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .home_dash {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(90px, auto);

    .box {
      padding: 10px 20px;
      box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
      background-color: $primary-white-color;
      border-radius: 10px;

      @include lg {
        padding: 15px;
      }
    }
    .box1 {
      background-color: #6940ac1f;
      font-family: poppinsRegular;
    }
    .box2 {
      display: flex;
      background-color: #6940ac1f;
      font-family: poppinsRegular;
      padding: 0px;

      .left_box {
        flex: 5;
        background-color: #6940ac3b;
        border-radius: 10px 60px 60px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          color: #1565c0;
          font-size: 13px;
          &:first-child {
            font-weight: bold;
          }
        }
      }
      .right_box {
        flex: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 10px;

        span {
          color: #313335;
          font-size: 10px;
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
    .box4 {
      background-color: #cbc82a2d;
      font-family: poppinsRegular;
    }
    .box3 {
      background-color: rgba(90, 175, 103, 0.199);
      font-family: poppinsRegular;
    }
    .box1,
    .box2,
    .box3,
    .box4 {
      @include md {
        grid-column: span 2;
      }
      @include sm {
        grid-column: span 2;
      }
    }

    .box_graph1 {
      grid-column: span 3;
      grid-row: span 2;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;

      // @include lg {
      //   grid-column: span 4;
      // }
      // @include sm {
      //   display: none;
      //   background-color: aqua;
      // }
      @include md {
        display: none;
      }
      @include lg {
        grid-column: span 4;
      }
    }
    .box_graph2 {
      grid-column: span 1;
      grid-row: span 2;
      @include lg {
        display: none;
      }
    }
    .box_list {
      grid-column: span 4;
      grid-row: span 2;
    }
  }
}

/* -------------------Old style------------------- */
.connexionBtn {
  display: flex;
  flex-direction: row;
}

.homeMenu {
  display: flex;
  align-items: center;
  gap: 10px;
}

.homeTitleItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeTitleItem2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.homeTitle {
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 2px solid #999999;
  color: #888;
}

.homeTitleSmall {
  color: #1565c0;
  font-weight: 600;
  font-size: 14px;
  padding: 3px 12px;
  background-color: #9db2d857;
  border-radius: 5px;
}

.iconPaiement {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.paymentContenair {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  /* background-color: #fff; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
}

.paiementMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.classeTitle {
  font-size: 14px;
  color: #888;
  font-weight: 600;
}

.dashboarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 8px;
  font-family: PoppinsRegular;
}

.dashbordItem {
  display: flex;
  flex-direction: column;
  border: 1px solid #dad9da;
  background-color: #fff;
  flex: 1;
  border-radius: 8px;
  min-width: max-content;
}

.dashbordItem-active {
  display: flex;
  flex-direction: column;
  border: 1px solid #dad9da;
  background-color: #fff;
  flex: 1;
  border-radius: 8px;
  //   box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.dashbordItemUp {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
}

.dashbordItemRetard {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
}

.dashDesc {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
}

/* .dashIcon {} */

.descTitle1 {
  color: #888;
  font-size: 13px;
  font-weight: 600;
  font-family: PoppinsRegular;
}

.descTitle2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #888;
  line-height: 24px;
  font-family: PoppinsRegular;
}

.dashbordItemDown {
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  color: #1976d2;
  cursor: pointer;
  font-family: PoppinsRegular;
}

.dashbordItemDown:hover {
  background-color: #eee;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dashbordItemDown-active {
  background-color: #9db2d857;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  color: #1976d2;
  cursor: pointer;
}

.graphicContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 20px;
}

.graphicItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  padding-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.graphicItemOption {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-right: 5px;
  justify-content: space-between;
  width: 100%;

  .seclectContainer {
    display: flex;
    gap: 20px;
  }
}

.graphicItemOption > h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #888;
  cursor: pointer;
}

.graphicItem > h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #3c3c3d;
}

.graphicItemOption > select,
select {
  height: 25px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  padding: 0px 15px;
  height: 35px;
  cursor: pointer;
  outline: none;
}

.inputDate > input {
  height: 25px;
  border: none;
  border-radius: 5px;
  padding: 0px 15px;
  height: 35px;
  font-size: 16px;
}

.inputDate > input:focus {
  border: none;
}

.graph {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.graphicItemOption > select > option {
  text-align: left;
}

a {
  text-decoration: none;
}

.paymentContenairBtn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  width: 100%;
  gap: 2rem;
}

.paymentField {
  flex: 1;
}

.tableList {
  margin-top: 0.3rem;
  background-color: #fff;
  height: 400px;
  overflow-y: auto;
}

.tableList > table {
  width: 100%;
  padding: 10px;
  border-radius: "0px 0px 35px 35px";
  text-align: center;
  color: #3c3c3d;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 300ms ease;
}

.tableList > table:hover {
  box-shadow: none;
}

.tableList > table tbody td {
  font-size: 12px;
  height: 2.5rem;
}

.tabRow {
  border-bottom: 1px solid #dad9da;
}

.tableList > table tbody tr:last-child td {
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 40px;
}

.cotation {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.cotationItem {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #5095d6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
}

.cotationItemNone {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #d3dee9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.cotationContent {
  display: flex;
  flex-direction: row;
  position: relative;
}

.cotationAlerte {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: rgb(238, 83, 83);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 15px;
}

.cotationAlerteNone {
  display: none;
}

.cotationBtn {
  cursor: pointer;
  color: #1976d2;
  text-align: right;
  font-style: italic;
  text-decoration: underline;
}

.mainLeftTitle {
  font-size: 16px;
  font-family: montserrat, serif;
  font-weight: 600;
  color: rgb(50, 50, 50);
}

.presenceOption {
  font-size: 13px;
  font-family: montserrat, serif;
  font-weight: 600;
  color: rgb(10, 10, 10);
}

.infoResult {
  font-size: 12px;
  border-radius: 3px;
  padding: 0px 2px;
  font-family: montserrat, serif;
  background-color: #5095d6;
  color: #fff;
}

.infoResultPerso {
  font-size: 14px;
  border-radius: 3px;
  padding: 0px 5px;
  font-family: montserrat, serif;
  /* font-weight: 300; */
  letter-spacing: 2px;
  background-color: #9fc9f0;
}

.optionContainerEb,
.classeTitleItem {
  color: #1565c0;
  font-weight: 600;
}

select.optionContainerEb {
  border: none;
}

.classeTitle {
  font-size: 15px;
  font-family: montserrat, serif;
  font-weight: 600;
  color: rgb(102, 102, 102);
  margin-right: 5px;
}

.classeNiveau {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.TableList {
  margin-top: 1rem;
}

.detailClasse {
  flex: 2;
}

.detail {
  display: flex;
  padding: 1rem 0rem;
  border: 1px dotted red;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 5px 0px 5px 5px,
    rgba(0, 0, 0, 0.16) 0px 0px 0px 0px;
}

.detailContent {
  display: flex;
  margin-top: 8px;
  gap: 10px;
}

.optionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
}

.optionInfoPerso {
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.optionInfoPersoNone {
  display: none;
}

.optionTitle {
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: montserrat, serif;
  font-weight: 500;
  color: rgb(10, 10, 10);
  /* padding-bottom: 10px; */
}

.optionRight {
  flex: 1;
  padding-left: 10px;
}

.optionLeft {
  flex: 1;
  padding-left: 10px;
}

.optionInfoDetail {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.optionInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  padding: 0rem 1rem;
}

.search-bar {
  padding: 5px 5px;
  border-radius: 5px;
  width: 500px;
  height: 35px;
  border: 1px solid #88888888;
  transition: 0.5s ease;
}

.search-bar-retard {
  padding: 5px 5px;
  border-radius: 5px;
  width: 300px;
  height: 35px;
  border: 1px solid #88888888;
  transition: 0.5s ease;
}

.selectClasse {
  padding: 5px 30px;
  outline: none;
}

.input-class > input:focus {
  padding: 5px 15px;
  border-radius: 5px;
  width: 550px;
  height: 35px;
  border: 1px solid #88888888;
  outline: none;
}

.input-class-retard > input:focus {
  padding: 5px 15px;
  border-radius: 5px;
  width: 300px;
  height: 35px;
  border: 1px solid #88888888;
  outline: none;
}

input:focus {
  border: none;
}

/* Ressources */

.ressource {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 20px;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ressourceAdd {
  width: 70%;
}

.ressourceAdd > .ressource_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ressourceAdd > .ressource_header > h6 {
  font-size: 14px;
  color: #999;
  padding: 0px 15px;
  margin-bottom: 15px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
}

.title {
  height: 35px;
  width: 100%;
  border-radius: 5px;
  border: 20x solid #999;
  padding-left: 10px;
  border: 1px solid #999;
}

.form > input {
  background-color: #dde9ff57;
}

.form > input:focus {
  outline: none;
  border: 1px solid #999;
  background-color: #b5cbf357;
}

.level_2 {
  display: flex;
  gap: 5px;
}

.level_2 input {
  height: 35px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid #999;
  border-radius: 5px;
}

.level_2 > input {
  background-color: #dde9ff57;
}

.level_2 > input:focus {
  outline: none;
  border: 1px solid #999;
}

.content {
  height: 180px;
  max-height: 200px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #999;
  padding-left: 10px;
  padding: 10px;
}

.form > textarea:focus {
  outline: none;
  border: 1px solid #999;
}

.ressource_btn {
  display: flex;
  gap: 5px;
  align-items: center;
}

.ressourceRecent {
  width: 30%;
  border-left: 1px solid #999;
  padding: 10px;
  position: relative;
}

.ressTitle {
  display: flex;
  justify-content: space-between;
}

.res_all {
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  color: #5095d6;
  border-bottom: 1px solid #5095d6;
  cursor: pointer;
}

.recent__title {
  color: #777;
  font-size: 12px;
}

.ressourceItem {
  display: flex;
  padding-top: 15px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #1565c0;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  margin-top: 6px;
}

.ressourceContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.res_top {
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: start; */
}

.ressourceName {
  font-size: 12px;
  color: #5095d6;
  font-weight: 400;
}

.ressoureSubcontent {
  display: flex;
  flex-direction: column;
}

.res_class {
  display: flex;
  gap: 3px;
}

.class_item,
.calss_level {
  font-size: 12px;
  font-weight: 400;
  color: #777;
}

.res_status {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 4px;
  color: #999;
  /* background-color: #5095d6; */
  border-radius: 3px;
}
