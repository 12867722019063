@import "../../../style/responsive.scss";
.topProfile {
  display: flex;
  gap: 20px;
  // margin: 0px 50px;

  .card_Year {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;

    .card-title {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    .item-list {
      list-style-type: none;
      padding: 0;
      margin: 15px 0;
      max-height: 200px;
      overflow-y: auto;
      background-color: #e5eaed;
    }

    .item-list li {
      padding: 8px;
      border-bottom: 1px solid #eee;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }
  }
}
.configuration_ {
  display: flex;
  justify-content: space-between;

  .homeMenuCong {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding-right: 10px;
    margin-right: 10px;
    color: #888;
    gap: 10px;
  }
  .right__ {
    display: flex;
    // gap: 10px;
    .add_button_pers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border: 1px solid #28a745;
      color: rgb(92, 92, 92);
      height: 25px;
      padding: 10px;
      cursor: pointer;
      margin-left: 10px;
      background-color: transparent;
      font-family: PoppinsRegular;
      font-size: 12px;
      .ico {
        font-size: 16px;
        color: #28a745;
      }
    }
  }
}
.stackMenu {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .body {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .item {
      display: flex;
      flex-direction: column;
      width: 18%;
      gap: 5px;

      label {
        width: 100%;
      }

      @include md {
        width: 48%;
      }

      &.phone {
        width: 18%;
        @include sm {
          width: 98%;
        }
        @include md {
          width: 98%;
        }
      }

      &.phone input {
        padding-left: 40px;
      }

      &.country {
        width: 37%;
        @include sm {
          width: 98%;
        }
        @include md {
          width: 98%;
        }
        // height: 30px;

        .menu-flags {
          width: 100%;
          // background-color: #28a745;
        }
        .menu-flags-button {
          padding: 3px 7px;
        }
      }
      &.orgCountry {
        width: 62%;
        // height: 30px;

        // .menu-flags {
        //   // padding: 3px 10px;
        // }
        .menu-flags-button {
          padding: 3px 7px;
        }
      }

      &.avenue {
        width: 93%;
      }

      &.orgMail {
        width: 54%;
      }

      @include sm {
        width: 45%;
      }

      label {
        color: #353535;
        font-size: 12px;
      }

      input {
        height: 30px;
        width: 100%;
        border: 1px solid #999;
        background-color: transparent;
        border-radius: 1px;
        padding: 0px 20px;

        &:focus {
          outline: none;
        }
      }

      select {
        height: 30px;
        width: 100%;
        border: 1px solid #999;
        border-radius: 2px;
        background-color: transparent;
        padding: 5px 20px;
        color: rgb(28, 28, 27);

        &:focus {
          outline: none;
        }
      }
    }
  }
  .add-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 30px;
    cursor: pointer;
  }

  .add-button:hover {
    background-color: #218838;
  }
}

.card_Classe {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
  width: 100%;

  .card-title {
    margin: 0;
    font-size: 18px;
    color: #333;
    font-size: 14px;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    width: 100%;

    .input-field {
      flex: 1;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .right_list_part {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .add_button_pers {
      border: 1px solid #28a745;
      color: white;
      width: 25px;
      height: 25px;
      // border: none;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      .ico {
        font-size: 16px;
        color: #28a745;
      }
    }

    .add-button:hover {
      background-color: #218838;
    }
  }

  .table_component {
    overflow: auto;
    width: 100%;
    max-height: 250px;
    margin-top: 10px;

    table {
      width: 100%;
      margin: 15px auto;
      border-collapse: collapse;
      font-family: Arial, sans-serif;
      color: #414040;
      background-color: lightgray;
      font-family: PoppinsRegular;
    }

    thead th {
      position: sticky; /* Makes header sticky */
      top: 0; /* Sticks to the top of the table */
      background-color: #afd9f5; /* Background color for the header */
      z-index: 1; /* Ensures the header is above the body */
    }

    th,
    td {
      border-bottom: 1px solid #ddd;
      padding: 10px;
      font-size: 13px;

      .userName {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &.statusSection {
        text-align: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // .activeIco {
        //   color: #28a745 !important;
        // }
      }
      &.headerSection {
        text-align: center;
        // .activeIco {
        // color: #7f1d18 !important;
        // }
      }

      .actionSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        gap: 10px;
        font-size: 11px;

        .more {
          color: rgb(251, 254, 255);
          padding: 2px 5px;
          background-color: #28a746a4;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .ico {
            font-size: 15px;
            color: #fff;
          }
        }
        .edit {
          color: rgb(251, 254, 255);
          padding: 2px 5px;
          background-color: #28a746a4;
          cursor: pointer;
        }
        .archive {
          color: #8b1f1f;
          // text-decoration: underline;
          padding: 2px 5px;
          background-color: #a73b284e;
          cursor: pointer;
          cursor: pointer;
        }
      }
      &.actionHeader {
        text-align: center;
      }
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
      text-align: left;
    }
    /* Alternate row colors */
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:nth-child(odd) {
      background-color: #e5eaed;
    }
  }
}
