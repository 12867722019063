.items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: PoppinsRegular;
  font-size: 13px;

  .items_info {
    display: flex;
    justify-content: space-between;
  }
}
.title_section {
  border-bottom: 1px solid #44444489;
  padding: 0px 5px 5px 5px;
}
.title_section_2 {
  border-bottom: 1px solid #44444489;
  border-top: 1px solid #44444489;
  padding: 5px;
}

td {
  font-size: 12px;
  padding: 2px 20px;
  align-items: center;

  &:nth-child(1) {
    text-transform: capitalize;
    color: #1f153f;
  }
  &:nth-child(2) {
    padding-left: 40px;
    text-transform: capitalize;
    // font-weight: bold;
  }
}
