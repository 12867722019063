@import "../../style/responsive.scss";
.topProfile {
  display: flex;
  gap: 20px;
  // margin: 0px 50px;

  .card_Year {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;

    .card-title {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    .item-list {
      list-style-type: none;
      padding: 0;
      margin: 15px 0;
      max-height: 200px;
      overflow-y: auto;
      background-color: #e5eaed;
    }

    .item-list li {
      padding: 8px;
      border-bottom: 1px solid #eee;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }
  }
  .card_Classe {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;

    .card-title {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      gap: 5px;

      .input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;
        margin-left: 10px;
      }

      .add-button:hover {
        background-color: #218838;
      }
    }

    .table_component {
      overflow: auto;
      width: 100%;
      max-height: 250px;
      margin-top: 10px;

      table {
        width: 100%;
        margin: 15px auto;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        color: #333;
        background-color: lightgray;
      }

      thead th {
        position: sticky; /* Makes header sticky */
        top: 0; /* Sticks to the top of the table */
        background-color: #afd9f5; /* Background color for the header */
        z-index: 1; /* Ensures the header is above the body */
      }

      th,
      td {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 14px;

        &.actionSection {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 11px;

          .edit {
            color: rgb(251, 254, 255);
            padding: 2px 5px;
            background-color: #28a745;
          }
          .archive {
            color: #8b1f1f;
            // text-decoration: underline;
            padding: 2px 5px;
            background-color: #a73b284e;
          }
        }
        &.actionHeader {
          text-align: center;
        }
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }
      /* Alternate row colors */
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:nth-child(odd) {
        background-color: #e5eaed;
      }
    }
  }
}
.configuration_ {
  display: flex;
  justify-content: space-between;

  .homeMenuCong {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding-right: 10px;
    margin-right: 10px;
    color: #888;
    gap: 10px;
  }
  .right_ {
    display: flex;
    gap: 10px;
    .btn_commande {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 6px 10px;
      border: 1px solid #218838;
      cursor: pointer;
      background-color: #218838;
      width: 100px;
      border-radius: 4px;
      color: white;
      // box-shadow: #0000003d 0px 3px 8px;
      @include md {
        display: none;
      }
      .ico {
        font-size: 16px;
      }

      &:hover {
        transition: 0.5s;
        // transform: scale(1.1);
        color: #218838;
        background-color: transparent;
      }
    }
    .ico_sm {
      background-color: #218838;
      border-radius: 50%;
      color: white;
      padding: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;

      &:hover {
        transition: 0.3s;
        transform: scale(1.1);
      }
      .ico_sm_ico {
        font-size: 20px;
        transition: 0.3s;
        &:hover {
          transform: scale(1.3);
        }
      }

      @include xxl {
        display: none;
      }
      @include md {
        display: flex;
      }
    }
    button {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 6px 10px;
      border: 1px solid #218838;
      cursor: pointer;
      color: #218838;
      width: 100px;
      border-radius: 4px;
      // box-shadow: #0000003d 0px 3px 8px;
      @include md {
        display: none;
      }
      .ico {
        font-size: 16px;
      }

      &:hover {
        transition: 0.5s;
        // transform: scale(1.1);
        color: #218838;
        background-color: white;
      }
    }
  }
}
.stackMenu {
  display: flex;
  padding: 10px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;
  margin-top: 20px;

  button {
    margin-right: 5px;
    cursor: pointer;
    text-transform: capitalize;
    // border: none;
    border: 1px solid #3883ec;
    color: #06063de4;
    background-color: transparent;
    padding: 4px 10px;
    // border-radius: 2px;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    &.active {
      border-bottom: none;
      color: #3883ec;
      font-weight: bold;
    }
  }
}
