$primary-color: #0091EA;
$secondary-color: #0545c0;
$transparent-color: #9db2d857;
$white-color: #ffffff;
$dark-color: #100f0f;
$text-color: #888;
$outline-color: #1e1e1e13;
$pink-color: rgba(205, 93, 73, 0.767);

.header {
    padding: 20px;
    margin-top: 15px;
    background-color: $white-color;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-between;

    .right {
        display: flex;
        gap: 30px;

        .item {
            display: flex;
            gap: 10px;
            // padding-right: 20px;
            // border-right: 2px solid $text-color;

            .leftitem {
                width: 50px;
                height: 50px;
                padding: 5px;
                border-radius: 25px;
                border: 2px solid $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon1 {
                    color: $primary-color;
                    font-weight: bold;
                }

                .icon2 {
                    color: $pink-color;
                }
            }

            .second {
                border: 2px solid $pink-color;
            }

            .rightItem {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .title {
                    font-size: 13px;
                    color: $text-color;
                }

                .num {
                    font-weight: 700;
                    color: $dark-color;
                }
            }

        }
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .icon3 {
            font-size: 40px;
            color: $primary-color;
        }

        .title {
            font-size: 13px;
            color: $text-color;
            font-style: italic;
            text-decoration: underline;
        }
    }
}