.chartContainer_col {
  display: flex;
  flex-direction: column;
  width: 100%;

  .chartHeader_col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #050f35d2;
    font-family: poppinsRegular;
    font-size: 13px;
  }
  .chartHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title_chart {
      color: #bcbcbc;
      font-family: poppinsRegular;
    }
    select {
      padding: 3px 5px;
      border: none;
      background-color: transparent;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      color: #767575;
      outline: none;
      width: fit-content;
      margin-top: -10px;
    }
  }
}
