   .container {
       display: flex;
       margin-top: 10px;
       gap: 10px;

       .left {
           display: flex;
           flex-direction: column;
           gap: 10px;
           padding-left: 10px;
           padding-right: 10px;
           width: 70%;
           border-right: 1px solid #999;

           .titleRes {
               display: flex;
               gap: 20px;
               align-items: center;
               border-bottom: 2px solid #cccccc;

               .titleR {
                   font-size: 15;
                   font-weight: 700;
               }
           }


           .type {
               display: flex;
               gap: 20px;
               align-items: center;
               border-bottom: 2px solid #cccccc;

               .titleR {
                   font-size: 18;
                   font-weight: 700;
               }
           }

           .classe {
               display: flex;
               gap: 20px;
               align-items: center;
               border-bottom: 2px solid #cccccc;

               .titleR {
                   font-size: 25;
                   font-weight: 700;
               }
           }


           .content {
               height: 180px;
               max-height: 200px;
               width: 100%;
               border-radius: 5px;
               border: 1px solid #999;
               padding-left: 10px;
               padding: 10px;
               overflow-y: scroll;

               .form>textarea:focus {
                   outline: none;
                   border: 1px solid #999;

               }

           }
       }

       .right {
           width: 30%;
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;

           .ressource_btn {
               display: flex;
               gap: 5px;
           }

           a {

               display: flex;
               flex-direction: column;
               align-items: center;

               .file {
                   margin-bottom: 35px;
                   display: flex;
                   flex-direction: column;
                   align-items: center;
                   justify-content: center;
                   width: 100px;
                   height: 100px;
                   background-color: rgb(222, 231, 233);
                   border-radius: 50%;

               }

               h5 {
                   font-size: 13px;
                   text-decoration: underline;
                   color: black;
                   cursor: pointer;
               }
           }


       }
   }