// MAIN TEXT COLORS
// $primary-green-color:#177c2ede;
$primary-green-color: #4a8029;
$soft-green-color: #5bca2b45;
$primary-blue-color: #0ea5eb;
$soft-blue-color: #0ea5eb4f;

$primary-orange-color: #6e560d;
$soft-orange-color: #cbc92a4c;

$secondary-green-color: #00b69b;

$primary-reserved-color: #00b69b;
$soft-reserved-color: #88665524;

$primary-notify-color: #ffffff;
$soft-notify-color: #ab1010e1;

$primary-red-color: #7b1e0b;
$soft-red-color: #c841233d;

$primary-white-color: #ffffff;
$secondary-white-color: #f5f6fa;

$primary-black-color: #000000;
$secondary-black-color: #616365;

$title_bg_color: #e8f0ea;
//MAIN BG COLORS
