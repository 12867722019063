.tableContent {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    max-height: 800px;
    height: 500px;
    overflow-y: scroll;

    h5 {
        font-size: 16px;
        color: #666;
        margin-left: 10px;
    }

    .List {
        margin-top: 20px;
        max-height: 800px;
        height: 460px;
        background-color: white;
        border-radius: 8px;
        padding: 0px 10px;


        .tab_header {
            width: 100%;
            border-collapse: collapse;

            thead {
                height: 20px;
                border-bottom: 1px solid #d3d1d1;
                text-align: left;

                tr {
                    border-radius: 10px 10px 0px 0px;
                    height: 50px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #d3d1d1;
                    background-color: #9db2d857;

                    th {
                        padding-left: 10px;
                        font-size: 12px;
                        color: #393737;

                    }
                }
            }

            tbody {
                tr {
                    height: 30px;
                    align-content: center;
                    border-bottom: .1px solid #bfbaba;

                    td {
                        color: #171717b0;
                        font-weight: 300;
                        padding-left: 10px;
                        font-size: 13px;
                        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

                        .actionBtn {
                            display: flex;
                            gap: 10px;
                            justify-content: center;

                            .edit {
                                display: flex;
                                align-items: center;
                                gap: 3px;
                                padding: 1px 3px;
                                background-color: rgba(171, 124, 124, 0.352);
                                border-radius: 3px;
                                color: #a43d3d;
                                cursor: pointer;
                                font-size: 12px;
                                font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                            }

                            .display {
                                display: flex;
                                align-items: center;
                                gap: 3px;
                                padding: 1px 3px;
                                background-color: rgba(93, 116, 158, 0.352);
                                border-radius: 3px;
                                color: #19509e;
                                cursor: pointer;
                                font-size: 12px;
                                font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
                            }
                        }
                    }
                }
            }
        }
    }
}