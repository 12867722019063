.container {
    display: flex;
    margin-top: 10px;
    gap: 10px;

    .left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 70%;

        border-right: 1px solid #999;
        //    background-color: #999;

        input {
            height: 35px;
            width: 100%;
            padding-left: 10px;
            border: 1px solid #999;
            border-radius: 5px;
        }

        input:focus {
            outline: none;
            border: 1px solid #999;
        }

        .title {
            height: 35px;
            width: 100%;
            border-radius: 5px;
            border: 20x solid #999;
            padding-left: 10px;
            border: 1px solid #999;
        }

        .level_2 {
            display: flex;
            gap: 5px;

            .level_2 input {
                height: 35px;
                width: 100%;
                padding-left: 10px;
                border: 1px solid #999;
                border-radius: 5px;

            }

            .level_2>input:focus {
                outline: none;
                border: 1px solid #999;
            }

        }

        .content {
            height: 180px;
            max-height: 200px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #999;
            padding-left: 10px;
            padding: 10px;
            overflow-y: scroll;

            &focus {
                outline: none;
                border: 1px solid #999;

            }
        }


        .publish_btn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;


            .check_btn {
                display: flex;
                align-items: center;

                .Pub_title {
                    font-size: 14px;
                    color: #888;
                }

            }
        }

    }

    .right {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        .ressource_btn {
            display: flex;
            gap: 5px;
            margin-top: 20px;
        }

        .file {
            margin-top: 45px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            h5 {
                font-size: 13px;
                text-decoration: underline;
                color: black;
                cursor: pointer;
            }
        }
    }
}