.adminMenus {
    background-color: white;

    .adminHeader {
        display: flex;
        justify-content: space-between;
    }

    .menu {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: center;
            align-items: center;

            .item-title {
                font-size: 12px;
                color: #777;
            }
        }
    }
}