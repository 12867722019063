.topTerminal {
  display: flex;
  gap: 20px;
  justify-content: center;

  .List_personnel {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 95%;
    font-family: PoppinsRegular;

    .headerPers {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-title {
        margin: 0;
        font-size: 18px;
        color: #333;
        font-size: 14px;
      }

      .input-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        gap: 5px;
        width: 50%;

        .input-field {
          flex: 1;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .add_button_pers {
          border: 1px solid #28a745;
          color: white;
          width: 25px;
          height: 25px;
          // border: none;
          border-radius: 50%;
          padding: 10px;
          cursor: pointer;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          .ico {
            font-size: 16px;
            color: #28a745;
          }
        }

        .add-button:hover {
          background-color: #218838;
        }
      }
    }

    .table_component {
      overflow: auto;
      width: 100%;
      max-height: 250px;
      margin-top: 10px;

      table {
        width: 100%;
        margin: 15px auto;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        color: #333;
        background-color: lightgray;
      }

      thead th {
        position: sticky; /* Makes header sticky */
        top: 0; /* Sticks to the top of the table */
        background-color: #afd9f5; /* Background color for the header */
        z-index: 1; /* Ensures the header is above the body */
      }

      th,
      td {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 14px;

        &.actionSection {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 11px;

          &:hover {
            display: flex;
          }

          .edit {
            color: #fbfeff;
            padding: 2px 5px;
            background-color: #28a745;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              color: #fbfeff;
              background-color: #28a746b7;
            }
          }
          .archive {
            color: #8b1f1f;
            padding: 2px 5px;
            background-color: #a73b284e;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
              color: #e8e1e1;
              background-color: #8b1f1f;
            }
          }
        }
        &.actionHeader {
          text-align: center;
        }
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }
      /* Alternate row colors */
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:nth-child(odd) {
        background-color: #e5eaed;
      }
    }
  }
}
