.motifSection {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;


    .motifInput {
        display: flex;
        gap: 5px;

        .button {
            padding: 3px 6px;
            font-size: 14px;
            cursor: pointer;
            color: #0091EA;
            border-radius: 5px;
            border: 2px solid #0091EA;

            &:hover {
                background-color: #0091EA;
                border-radius: 5px;
                color: #fff;
            }
        }

        .input {
            width: 80%;
            height: 30px;
            border-radius: 5px;
            border: 2px solid #0091EA;
            padding-left: 10px;


            &:focus {
                outline: none;
                border: 2px solid #0091EA;
                padding-left: 10px;
            }
        }
    }

    .motifShow {
        border: 1px solid #0091EA;
        border-radius: 5px;
        font-size: 14px;
        height: 60px;
        max-height: 50px;
        padding: 5px;
        max-width: 550px;
        overflow-x: hidden;
        margin-top: 5px;

        .motifText {
            font-style: oblique;
            font-size: 14px;
        }
    }

}