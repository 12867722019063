.homeMenu {
    display: flex;
    align-items: center;
    gap: 10px;

    .homeTitleItem {
        display: flex;
        justify-content: center;
        align-items: center;

        .homeTitle {
            font-size: 16px;
            font-weight: 600;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 2px solid #999999;
            color: #888;

        }
    }

    .homeTitleItem2 {
        display: flex;
        align-items: center;
        gap: 10px;

        .homeTitleSmall {
            color: #1565C0;
            font-weight: 600;
            font-size: 14px;
            padding: 3px 12px;
            background-color: #9db2d857;
            border-radius: 5px;
        }
    }

}

.homeTitleSmall {
    color: #1565C0;
    font-weight: 600;
    font-size: 14px;
    padding: 3px 12px;
    background-color: #9db2d857;
    border-radius: 5px;
}

.iconPaiement {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px
}