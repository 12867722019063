@import "../../../style/responsive.scss";

.delete_dial_box {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101c8;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-family: PoppinsRegular;
  font-size: 12px;

  .dialog_del {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 30vw;
    box-shadow: #1a1a1a59 0px 5px 15px;
    border-radius: 5px;
    padding: 15px;
    // height: fit-content;

    @include md {
      width: 60vw;
    }

    .header_sup {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #0e0d0d;
        font-size: 16px;
      }

      .ico {
        font-size: 15px;
        cursor: pointer;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      p {
        padding: 10px;
      }

      .del_Btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 25px;
          border: none;

          &:hover {
            cursor: pointer;
          }

          &:first-child {
            padding: 2px 5px;
            background-color: transparent;
            border: 1px solid #181818;
          }

          &:last-child {
            padding: 2px 5px;
            background-color: #8b1f1f;
            color: #ffffff;
          }
        }
      }
    }
  }
}
