@media print {
    @page {
        size: A4;
        margin: 20mm 16mm 16mm 16mm;
    }

    /* .header_Print {
        display: block;
        background-color: skyblue;
    } */
}

.header_Print {
    display: none;
    background-color: '#EEEEFF';
    /* color: rgb(176, 176, 191); */

    @media print {
        display: block;
        /* margin-bottom: '10px' */
        /* background-color: skyblue; */
    }
}